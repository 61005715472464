<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('TelemarketingScriptCreate')"
    @edit="(id) => onEdit('TelemarketingScriptEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'TelemarketingScripts',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'TelemarketingScripts',
      tableCaption: 'Скрипты телемаркетинга',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Скрипты телемаркетинга',
        },
      ],
      tableHeaders: [
        { text: 'Продукт телемаркетинга', alias: 'name', order: 'name' },
        { text: 'Скрипт', alias: 'script', order: 'script' },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
